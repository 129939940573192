.progress {
  background-color: #d8d8d8;
  height: 5px;
  margin: 0 auto;

  &__done {
    background-color: #fb9652;
    height: 100%;
    opacity: 0;
    width: 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    transition: 0.5s ease;
  }
}
@media (max-width: 768px) {
  .progress {
    margin: 0;
  }
}
