.editprofile {
  margin: 12rem auto 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__container {
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border: 2px solid grey;

    &__header {
      font-size: 1.5rem;
      margin: 0 auto 1rem auto;
      color: $color-primary;
    }

    &__content {
      margin: 5rem auto 2rem auto;
      width: 100%;
      display: grid;
      grid-gap: 2rem;
      gap: 2rem;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

      @include respond(tab-land) {
        width: 100%;
      }

      &__dp {
        justify-self: auto;
        position: relative;
        &__canvas {
          display: block;
          width: 15rem;
          height: 15rem;
          border-radius: 5%;
          &__label {
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            position: absolute;
            top: 45%;
            left: 45%;
            border: 1px solid $color-primary;
            background-color: white;
            border-radius: 50%;
          }
        }

        &__edit {
          &__input {
            position: absolute;
            top: 80%;
            left: 50%;
            &[type='file'] {
              display: none;
            }
          }
        }
      }

      &__static {
        color: $color-primary;
        margin: 0 0 0rem -7rem;
        justify-self: flex-start;

        @include respond(phone) {
          margin: 0 2rem;
          justify-self: center;
        }

        &__item {
          font-weight: 600;

          &__primary {
            font-size: 3rem;
            font-weight: 600;
          }

          &__secondary {
            font-size: 1.5rem;
            @include respond(phone) {
              font-weight: 600;
            }
          }
        }
      }

      &__section {
        margin: 2rem auto;
        width: 70%;
        @include respond(phone) {
          width: 90%;
        }

        &__header {
          &__heading {
            font-size: 2rem;
            color: $color-primary;
          }
        }

        &__form {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;

          &__label {
            font-size: 2rem;
            font-weight: 600;
            color: $color-primary;
          }

          &__input {
            width: 100%;
            padding: 1rem;
            margin: 0.5rem 0 2rem 0;
            outline: none;
            color: $color-primary;
            font-size: 1.5rem;
            font-weight: 500;
            border: 2px solid $color-primary;
            transition: 0.3s all;

            &::placeholder {
              color: $color-primary;
              font-weight: 600;
            }

            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }

          &__select {
            font-size: 1.5rem;
            padding: 1rem;
            color: $color-primary;
            margin: 0.5rem 0 2rem 0;
            border: 2px solid $color-primary;
            outline: 2px;
            background: $color-white;
          }

          &__radio {
            display: flex;
            justify-content: baseline;
            align-content: center;
            &__item {
              display: none;
              &__label {
                width: 40%;
                height: 6rem;
                font-size: 2rem;
                margin: 2rem 0;
                font-weight: 600;
                background: $color-white;
                color: $color-black;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 5px;
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
                transition: 0.3s all;

                &:last-child {
                  margin: 2rem 0 0 2rem;
                }
              }

              &:checked + &__label {
                width: 40%;
                height: 6rem;
                font-weight: 600;
                background: $color-primary;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
              }
            }
          }

          &__submit {
            padding: 1.5rem 0;
            border-radius: 3px;
            font-size: 1.6rem;
          }
        }
      }

      &__message {
        margin: 2rem 0 0 0;
      }
    }
  }
}
