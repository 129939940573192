h3 {
  display: inline;
}
.subheadings {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  border-bottom: 2px solid black;
  padding: 6px 10px;
  input {
    width: 1.8rem;
  }
}
.preferences-div {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 3rem;
  margin-top: 2rem;
  .preferences-heading {
    margin: 2rem 1.5rem;
    color: grey;
    font-weight: 600;
  }
  input {
    margin: 0 1rem;
  }
  .preferences {
    display: flex;
    margin: 2rem 0;
    .tagline {
      text-align: left;
    }
  }
  .preferences-list {
    width: 50%;

    select {
      margin: 1rem 0;
      background-color: rgba(0, 0, 0, 0.12);
      border: none;
      font-size: 1.75rem;
    }
  }
}
.notifications-heading {
  display: flex;
  justify-content: space-between;
}
.account-btn {
  border-radius: 8px;
  background-color: #d9d9d9;
  color: #666666;
  border: 1px solid transparent;
  font-weight: 900;
  padding: 1rem 3rem 1rem 3rem;
  outline: none;
}

.account-btn-changed {
  background-color: #58a700;
  color: #fff;
}
@media (max-width: 768px) {
  .preferences-div {
    margin: 0;
  }
  .learning-language {
    .learning-language-settings {
      margin: 2rem;
    }
  }

  .preferences-div {
    margin: 0;
  }

  .account-btn {
    padding: 0.25rem;
    font-size: 1.4rem;
    height: 1 rem;
    margin: 0.5rem 0;
  }
}
