/* Needed for fixed footer */
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Needed for fixed footer */
#page-content {
  flex: 1 0 auto;
}

.progress {
  background-color: gray;
  border-radius: 0%;

  .progress-bar {
    height: 100%;
    background-color: rgb(230, 198, 102);
    border-radius: 0%;
  }
}

.footer {
  background-color: #000;
  color: gray;
  width: 100%;
  display: block;
  margin-top: 16rem;
  flex-shrink: none;

  .footer-content {
    margin: 5rem;
    padding: 7rem 1rem 1rem 1rem;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 1.5rem;

    .footer-info {
      h1 {
        font-weight: 900;
        font-size: 3.5rem;
        color: #fff;
      }
      p {
        font-size: 1.5rem;
        margin-top: 1.8rem;
      }
    }

    .footer-links {
      .footer-links-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        h2 {
          font-weight: 700;
          font-size: 1.9rem;
          color: #fff;
        }

        .important-links {
          margin-right: 1rem;
          .important-links-progress {
            display: flex;
            flex-direction: row;
            margin-bottom: 2rem;
          }
          h2 {
            margin-bottom: 1rem;
          }
          a {
            text-decoration: none;
            color: gray;
          }
          p {
            font-size: 1.5rem;
            margin-top: 0rem;

            .link-dot-icon {
              font-size: 2.2rem;
              position: relative;
              top: 0.65rem;
              right: 0.5rem;
            }

            &:hover {
              color: #f2f2f2;
            }
          }
        }

        .newsletter {
          p {
            font-size: 1.5rem;
            margin-top: 3rem;
          }

          h2 {
            margin-bottom: 1rem;
          }

          .newsletter-progress {
            display: flex;
            flex-direction: row;
            margin-bottom: 2rem;
          }

          h3 {
            font-size: 2rem;
            margin-top: 1.8rem;
          }

          input {
            border: none;
            border-bottom: 1px solid gray;
            outline: none;
            background: transparent;
            width: 100%;
            color: gray;
          }

          .subscribe-btn {
            display: inline;
            background-color: rgb(251, 188, 5);
            color: #fff;
            padding: 0.375rem 4.75rem;
            font-size: 1rem;
            line-height: 2.5;
            border: 1px solid transparent;
            border-radius: 2rem;
            margin-top: 2rem;
            font-size: 1.3rem;
            font-weight: 900;

            &:hover {
              background-color: #e2aa03;
            }
          }
        }
      }
    }
  }

  .footer-social-media {
    background: #101010;

    .footer-social-media-content {
      margin: 0rem 5rem 0rem 5rem;
      padding: 2rem 0 2rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h5 {
        font-size: 1.5rem;
        color: #fff;
        font-style: italic;
      }

      a {
        font-size: 1.5rem;
        color: #fff;
        font-style: italic;
        text-decoration: none;
        margin-bottom: 1rem;
      }

      .social-media-links {
        display: flex;
        color: #fff;
        flex-direction: row;
        gap: 1rem;
        margin-right: 5rem;
        margin-left: 1rem;
        span {
          border-radius: 50%;
          background-color: rgb(251, 188, 5);
          padding: 0.7rem;
          font-size: 2rem;
          align-items: center;
          display: inline-flex;
          font-weight: 700;
          justify-content: center;
          vertical-align: middle;

          &:hover {
            background-color: #e2aa03;
          }
        }
      }
    }
  }

  @media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
    .footer-content {
      grid-template-columns: 100%;
      column-gap: 0;

      .footer-info {
        margin-bottom: 5rem;
      }

      .footer-links {
        .footer-links-container {
          flex-wrap: wrap;
          justify-content: flex-start;

          .important-links {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .footer-social-media-content {
      flex-wrap: wrap;

      h5 {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      a {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      .social-media-links {
        margin-left: 2rem;
      }
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
    .footer-content {
      grid-template-columns: 100%;
      column-gap: 0;

      .footer-info {
        margin-bottom: 5rem;
      }

      .footer-links {
        .footer-links-container {
          flex-wrap: wrap;
          justify-content: flex-start;

          .important-links {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .footer-social-media-content {
      flex-wrap: wrap;

      h5 {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      a {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      .social-media-links {
        margin-left: 2rem;
      }
    }
  }

  @media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
    .footer-content {
      grid-template-columns: 100%;
      column-gap: 0;

      .footer-info {
        margin-bottom: 5rem;
      }

      .footer-links {
        .footer-links-container {
          flex-wrap: wrap;
          justify-content: flex-start;

          .important-links {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .footer-social-media-content {
      flex-wrap: wrap;

      h5 {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      .social-media-links {
        margin-left: 2rem;
      }
    }
  }

  @media only screen and (min-device-width: 480px) and (max-width: 540px) {
    .footer-content {
      grid-template-columns: 100%;

      .footer-info {
        margin-bottom: 5rem;
      }
    }
  }

  @media only screen and (min-device-width: 541px) and (max-width: 768px) {
    .footer-content {
      column-gap: 2rem;

      .newsletter {
        h3 {
          font-size: 1.7rem !important;
        }

        input {
          font-size: 1.5rem !important;
        }
      }
    }
  }

  @media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  }

  @media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  }
}
