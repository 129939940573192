.cookie-box {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  position: fixed;
  bottom: 0vh;
  width: 100%;

  .title {
    font-size: 1.5rem;
    .close {
      float: right;
      cursor: pointer;
    }
  }
  .desc {
    font-size: 1.2rem;
  }
  a {
    color: white;
  }
  .btn-div {
    float: right;
    margin-right: 5vw;
    button {
      font-size: 1.2rem;
      padding: 10px 20px;
      border: none;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .manage-cookies {
    padding: 20px;
    font-size: 1.3rem;
    span {
      position: relative;
      top: -9px;
      left: 10px;
    }
  }
}
