.social-share-div {
  margin: 0 auto;
  width: fit-content;
  text-align: center;
  h3 {
    font-size: 1.5rem;
  }
  .share-buttons-div {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 2rem 0;
    a,
    a:hover,
    a:active {
      text-decoration: none;
      color: black;
    }
    .share-button {
      font-size: 3rem;
      margin: 0 1rem;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
@media (min-width: 768px) {
  .wh-icon {
    display: none;
  }
}
