.about {
  margin: 14rem 0 0 0;
  padding-bottom: 14rem;

  .about-hr-bar {
    border: 1px solid #efefef;
    margin-bottom: 3rem;
  }

  .inactive-about-tab-link {
    color: #afafaf;
  }

  .active-about-tab-link {
    color: #1cb0f6;
  }

  .inactive-about-bar {
    display: none;
  }

  .active-about-bar {
    border-bottom: 6px solid #1cb0f6;
  }

  @include respond(phone) {
    padding-bottom: 0;
  }

  @include respond(tab-port) {
    margin-bottom: 24rem;
  }

  @include respond(tab-land) {
    margin-bottom: 62rem;
  }

  &-content {
    margin: 5rem 30rem 5rem 30rem;

    h1 {
      font-size: 3rem;
      margin-bottom: 5rem;

      @include respond(phone) {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }

    &-tabs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include respond(phone) {
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      @include respond(tab-land) {
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      &-link {
        @include respond(phone) {
          // margin-top: 1rem;
        }
        h5 {
          font-size: 2rem;

          @include respond(phone) {
            font-size: 1.2rem;
          }
        }
      }
    }

    &-info {
      margin-top: 3rem;

      h2 {
        font-size: 2rem;
        margin: 2rem 0;
      }

      p {
        font-size: 1.5rem;
        color: #777;
        margin: 2rem 0;
      }
    }

    @include respond(phone) {
      margin: 5rem 3rem;
    }

    @include respond(small-phone) {
      margin: 5rem 3rem !important;
    }

    @include respond(tab-port) {
      margin: 5rem 15rem;
    }
  }
}
