.about-us-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .about-us-inner-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 120vh;
    background: linear-gradient(
      180deg,
      #f99529 0%,
      #f99529 75%,
      transparent 100%
    );
    .about-us-heading {
      font-size: 64px;
      flex-grow: 0;
      max-height: 140px;
      font-weight: 700;
      flex: 1 1;
      color: #fff;
      @include respond(phone) {
        max-height: 88px;
        font-size: 50px;
      }
    }
    .about-us-quote {
      font-size: 16px;
      width: 450px;
      max-height: 64px;
      text-align: center;
      color: #fff;
      flex: 1 1;
    }
  }
  .about-us-content-inner-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    min-height: 64vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 16%;
    font-size: 64px;

    @include respond(tab-land) {
      padding: 0 10%;
      font-size: 44px;
    }
    @include respond(tab-port) {
      padding: 0 6%;
      font-size: 48px;
    }
    @include respond(phone) {
      padding: 0 16px;
      font-size: 48px;
    }
    flex-direction: column;
    flex-wrap: wrap;
    .about-us-heading {
      flex: 1;
      width: 100%;
      text-align: center;
      max-height: 96px;
      font-weight: bold;
      color: #524439;
    }
    .about-us-content-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      @include respond(tab-port) {
        flex-direction: column-reverse;
      }
      .about-us-content-body {
        flex: 1;
        padding: 28px 40px;
        color: #524439;
        min-width: 40%;
        font-size: 16px;
        @include respond(big-desktop) {
          min-width: 440px;
        }
        @include respond(tab-port) {
          min-width: 240px;
        }
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #524439;
        }
        p {
          font-size: 16px;
        }
      }
      .about-us-image {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-grow: 0;
        min-width: 45%;
        img {
          object-fit: contain;
          width: 80%;
        }
      }
      .about-us-image-2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex-grow: 0;
        min-width: 30%;
        align-items: center;
        @include respond(tab-port) {
          display: none;
        }
        img {
          width: 174px;
          object-fit: cover;
          height: 262px;
        }
      }
    }
  }
}
.about-us-join-us-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  .about-us-join-us-content-heading {
    color: #524439;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 90%;
    margin: auto;
    min-height: 48px;
    border-bottom: 1px solid #00947b;
    @include respond(tab-land) {
      border-bottom: 1px solid transparent;
    }
    .about-us-join-us-content-heading-item {
      flex: 1 1 auto;
      flex-shrink: 1;
      flex-grow: 0;
      border-bottom: 4px solid #fff;
      text-align: center;
      font-size: 12px;
      cursor: pointer;
      min-width: 115px;
      padding: 0 16px;
      @include respond(tab-land) {
        flex-grow: 1;
      }
    }
    .active {
      border-bottom: 4px solid #00947b;
    }
  }
  .about-us-join-us-content-body {
    .about-us-join-us-content-body-item {
      font-size: 16px;
      padding: 64px 8%;
      .apply-button {
        text-decoration: none;
        color: white;
        background: #00947b;
        display: block;
        width: 160px;
        height: 48px;
        padding: 12px;
        text-align: center;
        border-radius: 8px;
        margin-left: auto;
        font-size: 16px;
      }
    }
  }
}
.divider {
  flex: 1;
  background: #00947b;
  border: 2px solid #00947b;
  border-radius: 24px;
  margin: 40px 20%;

  @include respond(phone) {
    margin: 0 40px;
  }
}
.my-64 {
  margin: 40px 0;
}
