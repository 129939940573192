.achievements-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .achievements-icons-dropdown {
    position: relative;
    display: flex;
    align-content: flex-start;
  }

  /* Dropdown Content (Hidden by Default) */
  .achievements-icons-dropdown-content {
    display: none;
    border-radius: 8px;
    position: absolute;
    background-color: #f9f9f9;
    width: 32rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    p {
      color: black;
      padding: 1.5rem 0rem 2.5rem 1rem;
      font-size: 1.5rem;
      text-align: left;

      span {
        font-size: 2.5rem;
        position: relative;
        top: 0.5rem;
        img {
          font-size: 0.8rem;
        }
      }
    }

    &::before,
    &::after {
      content: ' ';
      display: block;
      border-style: solid;
      border-width: 0 0.9em 1.2em 0.9em;
      border-color: transparent;
      position: absolute;
      left: 1.3rem;
    }

    &::before {
      top: -0.8em;
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }

    &::after {
      top: -0.7em;
      border-bottom-color: #fff;
    }
  }

  /* Show the dropdown menu on hover */
  .achievements-icons-dropdown:hover .achievements-icons-dropdown-content {
    display: block;
    top: 6.2rem;
    left: 0.5rem;
  }

  .drop-down-vocabulary {
    left: 0.4rem !important;
  }

  .drop-down-listening {
    left: 0.5rem !important;
  }

  .drop-down-speaking {
    left: -11.5rem !important;
    &::before,
    &::after {
      left: 13rem;
    }
  }

  .drop-down-reading {
    left: -26rem !important;
    &::before,
    &::after {
      left: 28rem;
    }
  }

  .drop-down-writing {
    left: -26.2rem !important;
    &::before,
    &::after {
      left: 28rem;
    }
  }

  .achievements-icons-item {
    color: grey;

    .achievements-icons-item-logo {
      position: relative;
      top: 10px;
      font-size: 3.5rem;
      img {
        font-size: 0.8rem;
      }
    }
    .top14 {
      top: 14px !important;
    }
    .achievements-icons-item-text {
      position: relative;
      bottom: 0.5rem;
      font-size: 2.4rem;
      font-weight: bold;
    }

    &:hover {
      color: #000;
    }
  }

  @media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
    .drop-down-listening {
      left: -11rem;
      margin-left: 2rem;
      &::before,
      &::after {
        left: 10rem;
      }
    }

    .drop-down-speaking {
      width: 30rem;
    }

    .drop-down-reading {
      margin-left: 7rem;
      &::before,
      &::after {
        left: 21rem;
      }
    }

    .drop-down-writing {
      margin-left: 1rem;

      &::before,
      &::after {
        left: 26.7rem;
      }
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
    .drop-down-reading {
      margin-left: 3rem;
    }

    .drop-down-listening {
      width: 29rem;
    }
  }

  @media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  }

  @media only screen and (min-device-width: 480px) and (max-width: 540px) {
  }

  @media only screen and (min-device-width: 541px) and (max-width: 768px) {
  }

  @media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  }

  @media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  }
}
