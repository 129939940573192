/* Needed for fixed logged-in-footer */
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Needed for fixed logged-in-footer */
#page-content {
  flex: 1 0 auto;
}

.logged-in-footer {
  background-color: #f99529;
  color: #000;
  width: 100%;
  display: block;
  margin-top: 16rem;
  flex-shrink: none;

  .logged-in-footer-content {
    padding: 3rem;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      color: #000;
    }

    p {
      font-size: 1.7rem;
      margin-left: 2rem;
      margin-right: 2rem;

      &:hover {
        color: #262626;
      }
    }

    .logged-in-social-media-icons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      color: #fff;
      margin-left: 2rem;
      margin-right: 2rem;
      span {
        border-radius: 50%;
        background-color: #262626;
        padding: 0.7rem;
        font-size: 2rem;
        align-items: center;
        display: inline-flex;
        font-weight: 700;
        justify-content: center;
        vertical-align: middle;

        &:hover {
          background-color: #666666;
        }
      }
    }
  }

  @media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
    .logged-in-footer-content {
      justify-content: space-between;
      align-content: flex-start;
      flex-direction: column;
      p {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      .logged-in-footer-links {
        display: none;
      }

      .logged-in-social-media-icons {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;

        span {
          padding: 1rem;
        }
      }
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
    .logged-in-footer-content {
      justify-content: space-between;
      align-content: flex-start;
      flex-direction: column;
      p {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
        font-size: 2rem;
      }

      .logged-in-footer-links {
        display: none;
      }

      .logged-in-social-media-icons {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;

        span {
          padding: 1rem;
        }
      }
    }
  }

  @media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
    .logged-in-footer-content {
      justify-content: space-between;
      align-content: flex-start;
      flex-direction: column;
      p {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
        font-size: 2rem;
      }

      .logged-in-footer-links {
        display: none;
      }

      .logged-in-social-media-icons {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;

        span {
          padding: 1rem;
        }
      }
    }
  }

  @media only screen and (min-device-width: 480px) and (max-width: 540px) {
    .logged-in-footer-content {
      justify-content: space-between;
      align-content: center;
      flex-direction: column;
      justify-content: center;
      p {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .logged-in-footer-links {
        display: none;
      }

      .logged-in-social-media-icons {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  @media only screen and (min-device-width: 541px) and (max-width: 768px) {
    .logged-in-footer-content {
      p {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .logged-in-social-media-icons {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 2rem;
      }
    }
  }

  @media only screen and (min-device-width: 769px) and (max-width: 1279px) {
    .logged-in-footer-content {
      p {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .logged-in-social-media-icons {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  @media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  }
}
