// For Auth
.modauth {
  margin: 12rem auto 0 auto;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__header {
    font-weight: 600;
  }

  &__content {
    margin: 2rem 0;

    &__grid {
      width: 100%;
      margin: 1rem 0;
      display: grid;
      gap: 2rem;
      justify-items: center;
      align-items: baseline;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

      &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__input {
          width: 100%;
          padding: 1rem;
          margin: 1rem 0 2rem 0;
          outline: none;
          color: $color-primary;
          font-size: 2rem;
          font-weight: 400;
          border: 2px solid $color-primary;
          transition: 0.3s all;

          &::placeholder {
            color: $color-primary;
            font-weight: 600;
          }

          &:focus {
            background-color: $color-primary;
            color: $color-white;
          }
        }

        &__select {
          width: 100%;
          text-align: center;
          padding: 1rem 3rem 1rem 1rem;
          margin: 1rem 0;
          background-color: $color-white;
          color: $color-primary;
          font-size: 2rem;
          font-weight: 600;
          border: none;
          border: 2px solid $color-primary;
          &__label {
            margin: 0 2rem 0 0;
            color: $color-white;
          }

          &__option {
            font-size: 2rem;
            border: none;
          }

          &:active {
            outline: none;
          }
        }

        &__error {
          color: red;
          margin-top: 1rem;
        }
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    color: $color-primary;
    margin: 2rem 0 0 0;
    font-size: 2rem;
    font-weight: 600;
  }
}

// For Dashboard
.moddashboard {
  margin: 12rem 0 0 0;
  &__header {
    &__heading {
      font-weight: 600;
      color: $color-primary;

      @include respond(tab-port) {
        font-size: 3rem;
      }

      @include respond(phone) {
        font-size: 2.5rem;
        margin: 0 1rem;
      }
    }
  }

  &__content {
    margin: 2rem auto 5rem auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-items: baseline;
    align-content: center;

    &__link {
      text-decoration: none;
      width: 100%;
      height: 100%;
      margin: 1rem auto;

      @include respond(tab-port) {
        width: 80%;
      }

      @include respond(phone) {
        width: 90%;
      }
    }

    @include respond(small-phone) {
      width: 100%;
      margin: 0 auto;
    }

    &__card {
      width: 100%;
      margin: 1rem auto;
      display: grid;
      gap: 2rem;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      &__item {
        background-color: $color-white;
        color: $color-primary;
        width: 100%;
        height: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
        transition: 0.5s all;

        @include respond(tab-port) {
          padding: 5rem;
        }

        &:hover {
          background-color: $color-primary;
          color: $color-white;
        }

        &__heading {
          font-size: 3rem;
          font-weight: 600;

          @include respond(phone) {
            font-size: 4rem;
          }
        }

        &__subheading {
          @include respond(phone) {
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  &__auth__signin__content__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem auto 1rem auto;
    width: 30%;

    &__label {
      font-size: 2rem;
    }

    &__input {
      width: 100%;
      padding: 1rem;
      margin: 2rem 0;
      outline: none;
      color: $color-primary;
      font-size: 2rem;
      font-weight: 400;
      border: 2px solid $color-primary;
      transition: 0.3s all;

      &::placeholder {
        color: $color-primary;
        font-weight: 600;
      }

      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    &__submit {
      margin-top: 1rem;
    }

    &__error {
      margin-top: 2rem;
      font-size: 2rem;
      font-weight: 800;
      color: red;
    }
  }
}

// For edit Words
.editwords {
  margin: 12rem auto 0 auto;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__header {
    font-weight: 600;
  }

  &__content {
    &__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__label {
        font-size: 2rem;
        font-weight: 600;
        color: $color-primary;
      }

      &__input {
        width: 100%;
        padding: 1rem;
        margin: 1rem 0 2rem 0;
        outline: none;
        color: $color-primary;
        font-size: 2rem;
        font-weight: 400;
        border: 2px solid $color-primary;
        transition: 0.3s all;

        &::placeholder {
          color: $color-primary;
          font-weight: 600;
        }

        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
}

.managewords__table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.managewords__td,
.managewords__th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.managewords__tr:nth-child(even) {
  background-color: #dddddd;
}

.add_question__form {
  &__label {
    display: block;
  }

  &__input {
    margin-bottom: 1.5rem;
  }
}

.selected_bulk_update {
  background-color: #ff8f00 !important;
}
