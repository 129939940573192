.updatepassword {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    &__heading {
      margin: 0 0 2rem 0;
      font-size: 3rem;
      color: $color-primary;
    }
  }

  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;

    @include respond(tab-port) {
      width: 90%;
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      &__email {
        width: 60%;
        padding: 1.5rem 2rem;
        // margin: 0.5rem 0 2rem 0;
        outline: none;
        color: $color-primary;
        font-size: 2rem;
        font-weight: 800;
        border: 2px solid $color-primary;
        transition: 0.3s all;
        margin: 0 0 2rem 0;

        @include respond(tab-port) {
          width: 90%;
        }

        @include respond(phone) {
          width: 100%;
        }

        &::placeholder {
          color: $color-primary;
          font-weight: 600;
        }

        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
      }

      &__button {
        // margin: 2rem 0 0 0;
        padding: 1.7rem 4rem;
        font-size: 2rem;
        font-weight: 600;
      }
    }

    &__message {
      font-size: 2rem;
      font-weight: 600;
      margin: 2rem auto 0 auto;
      color: $color-primary;
    }
  }
}
