.terms-and-conditions {
  margin: 12rem 0 0 0;
  font-size: 1.4rem;

  h1 {
    text-align: center;
    font-size: 4rem;

    @include respond(phone) {
      text-align: left;
      margin-left: 6rem;
    }
  }

  &-content {
    // margin: 5rem 25rem 5rem 25rem;
    margin: 6rem;

    @include respond(phone) {
      p {
        font-size: 1.5rem;
      }
    }

    h5 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.8rem;
      margin: 1.2rem 0 1.2rem 0;
    }

    ul {
      li {
        list-style-type: none;
        font-size: 1.75rem;
        margin: 1rem 0 1rem 0;
      }
    }
  }
}
