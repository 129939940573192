.quiz {
  margin: 0rem auto;
  width: 60vw;
  background-color: #ffffff;
  padding-bottom: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;

  .level-section {
    width: 95%;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .timer {
      font-size: 3rem;
      height: fit-content;
      align-self: center;
      color: rgb(239, 35, 60);
      font-weight: 800;
    }

    .level-name {
      width: 200px;
      text-align: center;
      font-weight: 700;
      font-size: 1.3rem;
      align-self: center;
    }
    .question-count {
      width: 200px;
      margin-top: 15px;
      margin-left: 20px;
      font-size: 1.5rem;
      font-weight: 800;
      text-align: right;
      align-self: center;
    }
  }
  .level-processbar {
    width: 90%;
    margin: 0 auto;
  }

  .question-section {
    width: 90%;
    justify-content: space-between;
    align-content: space-around;
    margin: 1.6rem auto 0 auto;

    .question-text {
      padding: 1rem 0;
      text-align: center;
      font-weight: 700;
      font-size: 2.5rem;
    }

    .answer-options {
      margin: 2rem auto 0 auto;

      .answer-button {
        margin: 0.5rem 0;
        padding: 0.8rem 0 0.8rem 2rem;
        width: 100%;
        text-align: left;
        display: block;
        background-color: #ffffff;
        border: 3px solid #f6c29e;
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.5rem;
        cursor: pointer;

        .option-text {
          padding: 0.5rem 0 0.5rem 4rem;
        }

        &:hover {
          background-color: #fb9652;
          color: #ffffff;
        }
      }
    }
  }

  .next-button {
    padding: 1.5rem 3rem;
    margin: 2rem auto;
    display: block;
    font-size: 1.5rem;
    background-color: #fb9652;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
}
@media (max-width: 768px) {
  .quiz {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    .level-section {
      padding: 20px 15px;
    }
    .next-button {
      margin: 0 auto;
      margin-top: 3rem;
    }
  }
}
