.greet-user-body {
  background: #fff;
  color: #000;
  width: 640px;
  min-height: 440px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include respond(small-phone) {
    width: 90%;
    min-height: 560px;
  }
  .greet-user-top-body {
    flex: 1;
    width: 100%;
    min-height: 160px;
    flex-grow: 0;
    /* background: #ff9800; */
    overflow: hidden;
    position: relative;
    .close {
      color: #999;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 8px;
      right: 8px;
      font-weight: bold;
      font-size: 2rem;
      cursor: pointer;
      z-index: 99;
      font-family: cursive;
    }
    .greet-user-top-body-heading {
      position: absolute;
      bottom: 24px;
      left: 0;
      color: #ff9800;
      text-align: center;
      width: 100%;
      margin-top: 32px;
      font-size: 2.6rem;
      line-height: 1.4;
      /* text-decoration: underline; */
      text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191,
        1px 2px 3px rgba(16, 16, 16, 0.38);
    }
    .background-image {
      object-fit: contain;
      width: 100%;
      height: 104px;
    }
  }
  .greet-user-bottom-body {
    position: relative;
    flex: 1;
    width: 100%;
    background: #ffffff;
    border-top: 1px solid #ccc;
    .greet-user-bottom-body-left {
      position: absolute;
      top: 16%;
      left: 8px;
      font-weight: bold;
      font-size: 3rem;
      cursor: pointer;
      color: #9e9e9e;
    }
    .greet-user-bottom-body-right {
      position: absolute;
      top: 16%;
      right: 8px;
      font-weight: bold;
      font-size: 3rem;
      cursor: pointer;
      color: #9e9e9e;
    }
    .greet-user-text {
      width: 80%;
      margin: auto;
      margin-top: 24px;
      font-size: 14px;
      font-weight: 700;
      color: #757575;
      .greet-user-text-item {
        position: relative;
        display: none;
        &.active {
          display: inline-block;
          // @include respond(small-phone) {
          //   flex-direction: column;
          // }
          img {
            object-fit: contain;
            float: left;
            height: 80px;
            width: 120px;
          }
          p {
            flex: 1;
            min-width: 80%;
          }
        }
      }
    }
    .skip-button {
      position: absolute;
      right: 48px;
      bottom: 12px;
      min-width: 36px;
      height: 36px;
      font-weight: bold;
      font-size: 16px;
      color: #9e9e9e;
      cursor: pointer;
      text-align: left;
      transition: 0.4s ease-in;
      display: flex;
      align-items: center;
    }
    .skip-button:hover {
      color: #ff9800;
    }
    .carousel-dot-buttons {
      position: absolute;
      bottom: 24px;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-flex;
      width: 120px;
      justify-content: space-evenly;
    }
    .carousel-span {
      width: 16px;
      height: 16px;
      background-color: #ccc;
      border-radius: 50px;
      cursor: pointer;
      &.active {
        background-color: #ff9800;
      }
    }
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
