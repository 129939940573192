// For Index
.adminindex {
  margin: 12rem auto 0 auto;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__header {
    font-weight: 600;
  }

  &__content {
    margin: 2rem 0;
    &__heading {
      font-size: 3rem;
    }

    &__link {
      display: block;
      text-decoration: none;
      height: 100%;
      width: 100%;
    }

    &__card {
      width: 100%;
      margin: 1rem 0;
      display: grid;
      gap: 2rem;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      &__item {
        background-color: $color-primary;
        width: 100%;
        height: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
        transition: 0.5s all;

        @include respond(tab-port) {
          padding: 5rem;
        }

        &:hover {
          transform: scale(1.05, 1.05);
        }

        &__heading {
          font-size: 3rem;
          color: $color-white;
          font-weight: 600;

          @include respond(phone) {
            font-size: 4rem;
          }
        }

        &__subheading {
          color: $color-white;

          @include respond(phone) {
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// For Auth
.adminauth {
  margin: 12rem auto 0 auto;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__header {
    font-weight: 600;
  }

  &__content {
    margin: 2rem 0;

    &__grid {
      width: 100%;
      margin: 1rem 0;
      display: grid;
      gap: 2rem;
      justify-items: center;
      align-items: baseline;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

      &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__input {
          width: 100%;
          padding: 1rem;
          margin: 1rem 0 2rem 0;
          outline: none;
          color: $color-primary;
          font-size: 2rem;
          font-weight: 400;
          border: 2px solid $color-primary;
          transition: 0.3s all;

          &::placeholder {
            color: $color-primary;
            font-weight: 600;
          }

          &:focus {
            background-color: $color-primary;
            color: $color-white;
          }
        }
      }
    }
  }

  &__link {
    color: $color-primary;
    margin: 2rem 0 0 0;
    font-size: 2rem;
  }
}

// For Dashboard
.admindashboard {
  margin: 12rem auto 0 auto;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__header {
    font-weight: 600;
  }

  &__content {
    margin: 2rem 0;

    &__link {
      display: block;
      text-decoration: none;
      height: 100%;
      width: 100%;
    }

    &__actioncards {
      width: 100%;
      margin: 1rem 0;
      display: grid;
      gap: 2rem;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      &__item {
        background-color: $color-primary;
        width: 100%;
        height: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
        transition: 0.5s all;

        @include respond(tab-port) {
          padding: 5rem;
        }

        &:hover {
          transform: scale(1.05, 1.05);
        }

        &__heading {
          font-size: 3rem;
          color: $color-white;
          font-weight: 600;

          @include respond(phone) {
            font-size: 4rem;
          }
        }

        &__subheading {
          color: $color-white;

          @include respond(phone) {
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.adminapproveinstructorrequests {
  margin: 12rem auto 0 auto;
  width: 50%;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &__header {
    font-weight: 600;
  }
}
