.goal-card-profile-setting {
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  margin-left: 5rem;
  width: 75%;
  height: fit-content;

  .profile-icon {
    border-style: dashed;
    border-width: 2px;
    color: #afafaf;
    border-radius: 50%;
  }

  .profile-sidebar-settings {
    margin-left: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 700;

    a {
      text-decoration: none;
      color: #000;
    }
  }
}

.inactive-setting {
  background-color: #fff;
  margin-bottom: 0.3rem;
}

.inactive-setting:hover {
  background-color: #d9d9d9;
  width: 95%;
  border-radius: 2rem;
}

.active-setting {
  background-color: #d9d9d9;
  width: 95%;
  border-radius: 2rem;
  margin-bottom: 0.3rem;
}

.settings-text {
  font-size: 16px;
  padding: 15px 20px;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .goal-card-profile-setting {
    display: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .goal-card-profile-setting {
    display: none;
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .goal-card-profile-setting {
    display: none;
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .goal-card-profile-setting {
    display: none;
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
}
