*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%;
  }

  @include respond(big-desktop) {
    // width < 1800?
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  background-color: $color-background;
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}
