.change-password {
  margin: 12rem 0 40rem 0;
  font-size: 1.4rem;

  .change-password-settings {
    margin: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
  }

  .change-password-form {
    margin-right: 2rem;

    .change-password-form-heading {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3.5rem;

      h1 {
        font-weight: 900;
        font-size: 27px;
      }
    }
    h3 {
      color: red;
      text-align: center;
    }

    .change-password-btn {
      border-radius: 8px;
      background-color: #d9d9d9;
      color: #666666;
      border: 1px solid transparent;
      font-weight: 900;
      padding: 1rem 3rem 1rem 3rem;
      &-active {
        border-radius: 8px;
        background-color: #58a700;
        color: #ffffff;
        border: 1px solid transparent;
        font-weight: 900;
        padding: 1rem 3rem 1rem 3rem;
      }
    }

    .change-password-form-content {
      margin-top: 7rem;

      .change-password-form-group {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 1rem 0rem 3rem 0rem;

        label {
          font-size: 1.5rem;
          font-weight: 700;
          margin-top: 1.5rem;
        }

        input {
          background-color: rgb(247, 247, 247);
          border: 2px solid rgb(229, 229, 229);
          color: rgb(75, 75, 75);
          font-size: 17px;
          font-stretch: 100%;
          font-weight: 400;
          padding: 5px 10px 5px 5px;
          text-align: left;
          width: 40rem;
          border-radius: 1rem;
          line-height: 4rem;
          outline: none;
        }

        .password-conditions {
          margin: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .change-password-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .change-password-form {
    margin-right: 0 !important;
    .change-password-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .change-password-btn {
      margin-top: 2rem;
    }

    .change-password-form-content {
      .change-password-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .change-password-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .change-password-form {
    margin-right: 0 !important;
    .change-password-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .change-password-btn {
      margin-top: 2rem;
    }

    .change-password-form-content {
      .change-password-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .change-password-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .change-password-form {
    margin-right: 0 !important;
    .change-password-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .change-password-btn {
      margin-top: 2rem;
    }

    .change-password-form-content {
      .change-password-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .change-password-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .change-password-form {
    margin-right: 0 !important;
    .change-password-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .change-password-btn {
      margin-top: 2rem;
    }

    .change-password-form-content {
      .change-password-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .change-password {
    margin-bottom: 100rem !important;
  }

  .change-password-settings {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .change-password-form {
    margin-right: 0 !important;
    .change-password-form-content {
      .change-password-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .change-password {
    margin-bottom: 100rem !important;
  }
  .change-password-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-bottom: 28rem !important;
  }
}
