.account {
  margin: 12rem 0 0 0;
  font-size: 1.4rem;

  .account-settings {
    margin: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
  }

  .account-form {
    margin-right: 2rem;

    .account-form-heading {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3.5rem;

      h1 {
        font-weight: 900;
        font-size: 27px;
      }
    }

    .account-btn {
      border-radius: 8px;
      background-color: #d9d9d9;
      color: #666666;
      border: 1px solid transparent;
      font-weight: 900;
      padding: 1rem 3rem 1rem 3rem;
      outline: none;
    }

    .account-btn-changed {
      background-color: #58a700;
      color: #fff;
    }

    .account-form-content {
      margin-top: 7rem;

      .non-input-content {
        display: flex;
        margin: 1rem 0rem 3rem 0rem;
        h5 {
          flex: 1;
          min-width: 100px;
          font-size: 1.5rem;
          font-weight: 700;
        }
        p {
          flex: 1;
          color: #999;
          min-width: 100px;
          padding-left: 16px;
          font-size: 1.4rem;
          font-weight: 700;
          text-transform: uppercase;
          margin: 6px;
        }
        @include respond(phone) {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            padding-left: 0;
            font-size: 1.9rem;
            min-width: 140px !important;
          }
        }
      }

      .account-form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0rem 3rem 0rem;

        label {
          flex: 1;
          min-width: 100px;
          font-size: 1.5rem;
          font-weight: 700;
        }

        input {
          flex: 1;
          background-color: rgb(247, 247, 247);
          border: 2px solid rgb(229, 229, 229);
          color: rgb(75, 75, 75);
          font-size: 17px;
          font-stretch: 100%;
          font-weight: 400;
          padding: 5px 10px 5px 5px;
          text-align: left;
          width: 40rem;
          border-radius: 1rem;
          line-height: 4rem;
          margin-left: 2rem;
          outline: none;
        }

        select {
          background-color: white;
          outline: none;
          box-shadow: none;
          border: 0px solid transparent;
          text-transform: uppercase;
          border-style: none;
          color: #999;
          font-size: 17px;
          font-stretch: 100%;
          font-weight: 700;
          padding: 5px 10px 5px 5px;
          text-align: left;
          width: 25rem;
          border-radius: 1rem;
          line-height: 4rem;
          margin-left: 14rem;
          @include respond(small-phone) {
            margin-left: 12rem;
          }

          option {
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .account-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .account-form {
    margin-right: 0 !important;
    .account-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .account-btn {
      margin-top: 2rem;
    }

    .account-form-content {
      .account-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .account-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .account-form {
    margin-right: 0 !important;
    .account-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .account-btn {
      margin-top: 2rem;
    }

    .account-form-content {
      .account-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .account-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .account-form {
    margin-right: 0 !important;
    .account-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .account-btn {
      margin-top: 2rem;
    }

    .account-form-content {
      .account-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .account-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .account-form {
    margin-right: 0 !important;
    .account-form-heading {
      flex-direction: column !important;
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .account-btn {
      margin-top: 2rem;
    }

    .account-form-content {
      .account-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .account-settings {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .account-form {
    margin-right: 0 !important;
    .account-form-content {
      .account-form-group {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .account {
    margin-bottom: 30rem !important;
  }
  .account-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
