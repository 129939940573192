.auth__signin {
  margin: 15rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    width: 100vw;
  }

  &__header {
    &__heading {
      font-size: 3rem;
      color: $color-primary;
    }
  }

  &__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include respond(tab-land) {
      width: 50%;
    }

    @include respond(tab-port) {
      width: 70%;
    }

    @include respond(phone) {
      width: 80%;
    }

    &__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      &__input {
        width: 100%;
        padding: 1rem;
        margin: 2rem 0;
        outline: none;
        color: $color-primary;
        font-size: 2rem;
        font-weight: 400;
        border: 2px solid $color-primary;
        transition: 0.3s all;

        &::placeholder {
          color: $color-primary;
          font-weight: 600;
        }

        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
      }

      &__submit {
        margin-top: 1rem;
        // @include respond(tab-land) {
        //   width: 100%;
        // }
      }

      &__error {
        margin-top: 2rem;
        font-size: 2rem;
        font-weight: 800;
        color: red;
      }
    }

    &__forgotpassword {
      margin: 2rem 0 0 0;
      font-size: 2rem;
      color: $color-primary;
      font-weight: 600;
    }
  }
}

.auth__signup {
  margin: 12rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    width: 100vw;
  }

  &__header {
    &__heading {
      margin: 0 0 2rem 0;
      font-size: 3rem;
      color: $color-primary;
    }
  }

  &__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include respond(tab-land) {
      width: 50%;
    }

    @include respond(tab-port) {
      width: 70%;
    }

    @include respond(phone) {
      width: 80%;
    }

    &__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      &__label {
        font-size: 2rem;
        font-weight: 600;
        color: $color-primary;
        text-align: left;
      }

      &__input {
        width: 100%;
        padding: 1rem 2rem;
        margin: 0.5rem 0 2rem 0;
        outline: none;
        color: $color-primary;
        font-size: 2rem;
        font-weight: 800;
        border: 2px solid $color-primary;
        transition: 0.3s all;

        &::placeholder {
          color: $color-primary;
          font-weight: 600;
        }

        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
      }

      &__select {
        font-size: 2rem;
        padding: 1rem 2rem;
        color: $color-primary;
        margin: 0.5rem 0 2rem 0;
        border: 2px solid $color-primary;
        outline: 2px;
        background: $color-white;
      }

      &__submit {
        margin: 1rem 0 5rem 0;
        @include respond(tab-land) {
          width: 100%;
        }
      }

      &__error {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        font-size: 2rem;
        font-weight: 800;
        color: red;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-width: 1279px) {
  .auth__signin {
    margin-bottom: 48rem !important;
  }
}

@media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  .auth__signin {
    margin-bottom: 75rem !important;
  }
}
