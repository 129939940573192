.dashboard {
  margin: 12rem 0 0 0;
  &__header {
    &__heading {
      font-weight: 600;
      color: $color-primary;

      @include respond(tab-port) {
        font-size: 3rem;
      }

      @include respond(phone) {
        font-size: 2.5rem;
        margin: 0 1rem;
      }
    }
  }

  &__content {
    margin: 2rem auto 5rem auto;
    display: flex;
    flex-direction: column;
    justify-items: baseline;
    align-content: center;

    &__link {
      text-decoration: none;
      color: $color-white;
      width: 60%;
      margin: 1rem auto;

      @include respond(tab-port) {
        width: 80%;
      }

      @include respond(phone) {
        width: 90%;
      }
      &__card {
        border-radius: 5px;
        background-color: $color-primary;
        box-shadow: 0rem 0 2rem rgba($color: $color-black, $alpha: 0.1);
        display: flex;
        justify-content: space-between;
        padding: 5rem;
        transition: 0.5s all;

        @include respond(phone) {
          padding: 2rem;
        }

        &__heading {
          font-size: 2rem;
        }

        &__progress {
          font-size: 2rem;
          color: $color-black;
        }

        &:hover {
          transform: scale(1.05, 1.1);
        }
      }
    }

    @include respond(small-phone) {
      width: 100%;
      margin: 0 auto;
    }

    &__cardlink {
      height: 100%;
      width: 100%;
      text-decoration: none;
    }
  }
}
