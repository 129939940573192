.contact-us {
  margin: 18rem 0 0 0;
  padding-bottom: 14rem;

  @include respond(tab-land) {
    margin-bottom: 35rem;
  }

  &-content {
    margin: 1rem 5rem 5rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    @include respond(phone) {
      margin: 3rem;
      justify-content: flex-start;
      align-items: flex-start;
    }

    button {
      margin-top: 1rem;
      width: 30%;
      border-radius: 5rem;
      background-color: #f99529;
      color: #fff;
      border: 1px solid transparent;
      font-weight: 700;
      padding: 1rem;
      outline: none;
      font-size: 1.5rem;

      @include respond(phone) {
        width: 100%;
      }

      @include respond(tab-land) {
        width: 100%;
      }

      &:hover {
        background-color: #f98406;
      }
    }

    &-card {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border-radius: 0.8rem;
      margin-top: 5rem;
      padding: 3rem 0;
      width: 85%;

      h1 {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 5rem;
      }

      &-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3rem;
        padding: 0 5rem;
        margin: 2rem 5rem 0 5rem;

        @include respond(phone) {
          flex-direction: column;
          flex-wrap: wrap;
          margin: 1rem;
          padding: 0 2rem;
        }

        @include respond(tab-land) {
          margin: 1rem;
        }

        h5 {
          font-size: 3rem;
          font-weight: 500;
          color: #272727;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1.5rem;
          color: #696969;
        }

        &-address {
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
          background-color: #fff;
          width: 50%;
          padding: 4rem 3rem;

          .address-content {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin: 3rem 0;

            @include respond(small-phone) {
              flex-direction: column;
            }

            .address-icon {
              border-radius: 50%;
              border: 1px solid #ff5700;
              color: #ff5700;
              padding: 1.5rem;
              width: 1.5rem;
              height: 1.5rem;
              font-size: 1.3rem;
              font-weight: 700;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              vertical-align: middle;

              span {
                color: #000;
              }
            }
          }

          @include respond(phone) {
            width: 100%;
          }

          @include respond(tab-land) {
            width: 100%;
          }
        }

        &-form {
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
          background-color: #fff;
          width: 80%;
          padding: 4rem 3rem;

          @include respond(phone) {
            width: 100%;
          }

          @include respond(tab-land) {
            width: 100%;
          }

          form {
            margin: 0 5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include respond(phone) {
              margin: 1rem;
            }

            @include respond(tab-land) {
              margin: 1rem;
            }

            .contact-form-input {
              height: 33px;
              width: 100%;
              background: transparent;
              border: none;
              border-bottom: 1px solid #b4b4b4;
              font-size: 14px;
              font-weight: 500;
              color: #696969;
              letter-spacing: 0;
              margin-bottom: 16px;
              outline: none;
            }
          }
        }
      }

      @include respond(phone) {
        width: 100%;
      }
    }
  }
}
