.btn {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: $color-primary;
    color: $color-white;
    font-weight: 600;
    transition: 0.3 all;
    @include respond(tab-land) {
      font-size: 3rem;
    }

    @include respond(tab-port) {
      font-size: 2rem;
      padding: 2rem 8rem;
    }

    @include respond(small-phone) {
      font-size: 2rem;
      padding: 2rem 3rem;
    }
  }

  &:hover {
    box-shadow: 0rem 1rem 2rem rgba($color: $color-black, $alpha: 0.2);
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0rem 0.5rem 1rem rgba($color: $color-black, $alpha: 0.2);
  }
}

.btn-sm {
  text-decoration: none;
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight: 600;
  box-shadow: 0rem 0.5rem 1rem rgba($color: $color-black, $alpha: 0.2);

  &:hover {
    box-shadow: 1rem 1rem 2rem rgba($color: $color-black, $alpha: 0.2);
    transition: 0.5s all;
  }
}

.btn-md {
  text-decoration: none;
  padding: 1.5rem 3rem;
  font-size: 2rem;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0rem 0.5rem 1rem rgba($color: $color-black, $alpha: 0.2);

  &:hover {
    box-shadow: 1rem 1rem 2rem rgba($color: $color-black, $alpha: 0.2);
    transition: 0.5s all;
  }
}

.btn-lg {
  text-decoration: none;
  padding: 1.5rem 4rem;
  font-size: 2rem;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0rem 0.5rem 1rem rgba($color: $color-black, $alpha: 0.2);

  &:hover {
    box-shadow: 1rem 1rem 2rem rgba($color: $color-black, $alpha: 0.2);
    transition: 0.5s all;
  }
}
