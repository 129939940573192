.emailverify__content__form__button {
  padding: 16px 80px;
}
.emailverify {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    &__heading {
      font-size: 36px;
      color: '#000';
    }
  }
}
.emailverify-otp-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 0 16px;
}
.emailverify-otp-input-inner-container {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 0 1px;
  z-index: 6;
  overflow: hidden;
}
.emailverify-otp-input {
  padding: 8px 4px;
  width: 80px;
  height: 64px;
  font-size: 36px;
  border-width: 0 1px 0 0;
  border-color: #000;
  background: #f6f6f6;
  outline: 0;
  color: #000;
  text-align: center;
}
.emailverify-otp-button {
  font-weight: 700;
  color: #000;
  cursor: pointer;
  font-size: 32px;
  width: 300px;
  margin: auto;
}

.emailverify-body {
  text-align: center;
  font-size: 16px;
  color: darkslategray;
}
.dash-container {
  min-width: 40px;
  .dash {
    width: 16px;
    background: black;
    height: 4px;
    margin: auto;
    border-radius: 8px;
  }
}
