.all-notifications {
  margin: 120px 72px;
  @media (max-width: 960px) {
    margin: 120px 20px;
  }
  .all-notifications-header {
    font-size: 24px;
    margin: 0 24px;
    color: darkslategray;
  }
  .all-notifications-body {
    padding: 0 20px 0;
    background: #fff;
    border-radius: 16px;
    .all-notifications-body-container {
      margin: 20px auto;

      .tabs-buttons {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        flex-shrink: 1;
        border-bottom: 3px solid #f99529;
        .tabs-button-item {
          margin: 16px 4px;
          font-size: 17px;
          padding: 4px 8px;
          background: #afafaf;
          color: white;
          border-radius: 8px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex-grow: 1;
          justify-content: center;
          cursor: pointer;
          span {
            margin: 0 8px;
          }
        }
        .active {
          background: #f99529;
        }
      }
      .tabs-body {
        padding: 20px 0;
        font-size: 17px;
        .tabs-notifications-item {
          width: 100%;
          display: flex;
          flex-flow: wrap;
          justify-content: flex-start;
          border-bottom: 0.12em solid #d8d8d8;

          .tabs-notifications-item-icon {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            background: #ff6442;
            border-radius: 50px;
            margin: 16px;
            color: white;
            font-weight: 700;
          }
          .tabs-notifications-item-info {
            width: 80%;
            sm {
              font-size: 12px;
            }
            p:nth-child(2) {
              font-weight: 700;
            }
            p:nth-child(3) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
