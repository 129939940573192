.alert-body-container {
  display: flex;
  position: relative;
  background: white;
  padding: 32px 120px;
  border-radius: 8px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 16px;
    cursor: pointer;
  }
  .alert-header {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .alert-body {
    font-size: 16px;
    margin-bottom: 64px;
  }
  .alert-button {
    position: absolute;
    right: 4%;
    bottom: 4%;
    border: 3px solid #ff9800;
    border-radius: 8px;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 900;
    color: #ff9800;
    cursor: pointer;
  }
}
