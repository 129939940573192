.learn {
  margin: 12rem 0 0 0;
  &__header {
    &__heading {
      font-weight: 600;
      color: $color-primary;
    }
  }
  .activate-prompt {
    position: relative;
    text-align: center;
    top: -2rem;
    margin: 0 7rem;
    padding: 2rem;
    font-size: 1.5rem;
    border-radius: 5px;
    background-color: rgb(255, 199, 87);
  }

  .cardlink {
    height: 100%;
    width: 100%;
    text-decoration: none;
    margin: 2rem 0;
  }

  .learn-row {
    display: grid;
    grid-template-columns: 60% 40%;
    column-gap: 5rem;
    margin: 2rem 11.5rem 5rem 6rem;
  }

  &__content {
    // margin: 5rem 10rem 5rem 6rem;
    display: grid;
    gap: 2rem;
    justify-items: center;
    align-items: center;
    // grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    // grid-template-columns: repeat(2, minmax(375px, 0.35fr));
    // grid-template-columns: repeat(2, minmax(375px, 0.2fr));
    grid-template-columns: 50% 50%;

    &__card {
      box-shadow: 0rem 0.2rem 1.2rem rgba($color: $color-black, $alpha: 0.1);
      border-radius: 0.5rem;
      width: 100%;

      .takequizbtn {
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        cursor: pointer;
        padding: 1rem;
        width: 100%;
        margin: 0 auto 0 auto;
        display: block;
        border: none;
        // background-color: #d1d1d1;
        // background-color: $color-primary;
        background-color: #198754;
        outline: none;
        border-top: white 2px solid;

        &:hover {
          // background-color: #ffb420;
          background-color: #146c43;
          // color: black;
          color: #fff;
          transition: 0.3s all ease;
        }
      }

      .takequizbtnlocked {
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        pointer-events: none;
        padding: 1rem;
        width: 100%;
        margin: 0 auto 0 auto;
        display: block;
        border: none;
        background-color: #d1d1d1;
        border-top: white 2px solid;
      }
    }

    &__cardblocked {
      box-shadow: 0rem 0.2rem 0.4rem rgba($color: $color-black, $alpha: 0.1);
      border-radius: 0.5rem;
      width: 100%;
      pointer-events: none;
      cursor: default;

      .takequizbtnlocked {
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        pointer-events: none;
        padding: 1rem;
        width: 100%;
        margin: 0 auto 0 auto;
        display: block;
        border: none;
        background-color: #d1d1d1;
        border-top: white 2px solid;
      }

      .cardlink {
        // letter-spacing: 1rem;
        letter-spacing: inherit;
      }
    }

    @include respond(small-phone) {
      width: 100%;
      margin: 0 auto;
    }

    // &__quizlink {
    //   margin: 0;
    //   gap: 0;
    //   display: inline-block;
    // }
  }
}

.user_progress_large {
  // position: absolute;
  // right: 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.user_progress_small {
  display: none;
}

.user_progress_card {
  box-shadow: 0rem 0.2rem 1rem rgba($color: $color-black, $alpha: 0.1);
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  padding: 2rem;
  width: 100%;
  height: fit-content;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .learn-row {
    margin: 1rem !important;
    grid-template-columns: 100% !important;
  }

  .user_progress_large {
    display: none;
  }

  .user_progress_small {
    display: inherit;
    margin: 5rem 1rem 5rem 1rem;
  }

  .user_progress_card {
    width: 100%;
  }

  .learn__content {
    grid-template-columns: repeat(1, minmax(260px, 1fr));
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .learn-row {
    grid-template-columns: 100% !important;
    margin: 2rem 3rem !important;
  }

  .user_progress_large {
    display: none;
  }

  .user_progress_small {
    display: inherit;
    margin: 5rem 2rem 5rem 2rem;
  }

  .user_progress_card {
    width: 100%;
  }

  .learn__content {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .learn {
    margin: 10rem 0 0 0;
    .activate-prompt {
      top: 0;
      margin: 3rem;
    }
  }
  .learn-row {
    grid-template-columns: 100% !important;
    margin: 2rem 2rem !important;
  }

  .user_progress_large {
    display: none;
  }

  .user_progress_small {
    display: inherit;
    margin: 5rem 2rem 5rem 2rem;
  }

  .user_progress_card {
    width: 100%;
  }

  .learn__content {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .learn-row {
    grid-template-columns: 100% !important;
    margin: 2rem !important;
  }

  .user_progress_large {
    display: none;
  }

  .user_progress_small {
    display: inherit;
    margin: 2rem;
  }

  .user_progress_card {
    width: 100%;
  }

  .learn__content {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .user_progress_large {
    display: none;
  }

  .user_progress_small {
    display: inherit;
    margin: 3rem;
  }

  .user_progress_card {
    width: 100%;
  }

  .learn__content {
    grid-template-columns: repeat(2, minmax(330px, 1fr));
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .learn-row {
    grid-template-columns: 100%;
  }

  .user_progress_large {
    display: none;
  }

  .user_progress_small {
    display: inherit;
    margin: 5rem;
  }

  .user_progress_card {
    width: 100%;
  }

  .learn__content {
    // grid-template-columns: repeat(2, minmax(375px, 1fr));
    grid-template-columns: 100% 100%;
    // margin-left: 5rem;
    margin-right: 9rem;
  }
}

@media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  // .user_progress_large {
  //   position: absolute;
  //   right: 1rem;
  // }

  // .learn__content {
  //   grid-template-columns: repeat(2, minmax(360px, 0.2fr));
  //   margin: 5rem 2rem 5rem 6rem;
  // }
}
