.categorycard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5rem;
  cursor: pointer;
  color: $color-primary;
  box-shadow: 0rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
  @include respond(small-phone) {
    width: 100%;
  }

  &__heading {
    font-size: 4rem;
    font-weight: 600;
  }

  &__processbar {
    background-color: #d8d8d8;
    height: 10px;
    width: 200px;
    border-radius: 20px;
    margin-top: 1rem;

    &__done {
      background-color: $color-primary;
      height: 100%;
      opacity: 0;
      width: 0;
      border-radius: 20px;
      display: flex;
      align-items: center;
      transition: 0.5s ease;
    }
  }

  &__subheading {
    font-size: 3rem;
    font-weight: 400;
  }

  &:hover {
    color: $color-white;
    background-color: $color-primary;
    box-shadow: 2rem 2rem 5rem rgba($color: $color-black, $alpha: 0.2);
    transition: 0.5s all;

    .categorycard__processbar {
      .categorycard__processbar__done {
        background-color: $color-white;
        transition: 0.1s all;
      }
    }
  }
}

// .categorycardlocked {
//   pointer-events: none;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   width: 100%;
//   padding: 5rem;
//   cursor: pointer;
//   color: $color-primary;

//   @include respond(small-phone) {
//     width: 90%;
//   }

//   &__heading {
//     font-size: 4rem;
//     font-weight: 600;
//   }

//   &__processbar {
//     background-color: #d8d8d8;
//     height: 10px;
//     width: 200px;
//     border-radius: 20px;
//     margin-top: 1rem;

//     &__done {
//       background-color: $color-primary;
//       height: 100%;
//       opacity: 0;
//       width: 0;
//       border-radius: 20px;
//       display: flex;
//       align-items: center;
//       transition: 0.5s ease;
//     }
//   }

//   &__subheading {
//     font-size: 3rem;
//     font-weight: 400;
//   }

//   &:hover {
//     color: $color-white;
//     background-color: $color-primary;
//     box-shadow: 2rem 2rem 5rem rgba($color: $color-black, $alpha: 0.2);
//     transition: 0.5s all;

//     .categorycard__processbar {
//       .categorycard__processbar__done {
//         background-color: $color-white;
//         transition: 0.1s all;
//       }
//     }
//   }
// }

.vocabcards {
  &__note {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    margin: 7rem auto 0 auto;
    align-items: center;
    padding: 1rem 2rem 0 2rem;

    &__loopimage {
      height: 2rem;
      padding-right: 0.4rem;
    }

    @include respond(small-phone) {
      margin: 12rem auto 0 auto;
      width: 100%;
      justify-content: center;
    }

    @include respond(phone) {
      margin: 12rem auto 0 auto;
      width: 100%;
      justify-content: center;
    }

    @include respond(tab-port) {
      width: 90%;
      // margin: 12rem auto 2rem auto;
    }
  }
}

.vocabcard {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // justify-content: space-between;
  width: 60%;
  // padding: 5rem;
  margin: 2rem auto 2rem auto;
  border-radius: 1rem;
  background: #f1f0f0;
  color: #525252;
  // box-shadow: 0rem 0 2rem rgba($color: $color-black, $alpha: 0.1);
  overflow: hidden;

  @include respond(tab-port) {
    width: 90%;
    // margin: 12rem auto 2rem auto;
  }

  @include respond(small-phone) {
    height: auto;
    // margin: 12rem auto 2rem auto;
  }

  &__content {
    // margin: 0 0 5rem 0;
    padding: 0 5rem;

    &__wordstatus {
      font-size: 1.5rem;
      font-weight: 600;
      text-align: end;
      animation: pop 0.3s both;
      margin-top: 0.5rem;
    }
    &__category {
      font-size: 1.5rem;
      font-weight: 700;
    }

    &__heading {
      font-size: 5rem;
      font-weight: 600;
      text-align: center;
      animation: pop 0.3s both;
      padding-bottom: 1.4rem;

      @include respond(small-phone) {
        font-size: 5rem;
        margin: 1rem 0 3rem 0;
      }

      @include respond(phone) {
        margin: 1rem 0 3rem 0;
      }

      &__after {
        font-size: 5rem;
        font-weight: 600;
        text-align: center;
        animation: pop 0.3s both;
        margin: 20px 0;

        @include respond(small-phone) {
          font-size: 5rem;
        }
      }
    }

    &__subheading {
      font-size: 3rem;
      font-weight: 400;
      animation: pop 0.3s both;

      &__main {
        font-size: 5rem;
        font-weight: 600;
        text-align: center;
        padding: 6rem 0;
        animation: pop 0.3s both;
      }
    }
  }

  &__actions {
    width: 100%;
    margin: auto 0 0 0;
    display: flex;
    justify-items: center;
    align-items: center;
    animation: pop 0.3s both;
    overflow: hidden;

    @include respond(small-phone) {
      width: 100%;
    }

    div {
      transform: scale(1.5);
    }

    &__secondary {
      width: 100%;
      height: 100%;
      background-color: #f35656;
      color: #ffffff;
      padding: 1.5rem 3rem;
      &:hover {
        transform: scale(1.06);
        flex-grow: 1;
      }
    }

    &__primary {
      width: 100%;
      height: 100%;
      background-color: #77e266;
      color: #ffffff;
      padding: 1.5rem 3rem;
      &:hover {
        transform: scale(1.06);
        flex-grow: 1;
      }

      &__show_meaning {
        width: 100%;
        height: 100%;
        padding: 1.5rem 3rem;

        @include respond(phone) {
          padding: 6rem 3rem;
        }
        div {
          transform: scale(1);
        }

        &:hover {
          div {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }

    &__after {
      display: flex;
      margin-top: auto;

      &__actionbtn {
        width: 50%;
        text-align: center;
      }
    }
  }

  @keyframes pop {
    0% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    100% {
      -webkit-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
    }
  }

  &__endlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__processbars {
    width: 60%;
    margin: 0 auto;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 2rem 5rem;
      color: $color-white;
      border-radius: 5px;

      &__heading {
        font-size: 2rem;
      }

      &__percentage {
        font-size: 5rem;
      }
    }

    #knownWords {
      background-color: #61ff6e;
    }

    #unknownWords {
      background-color: #ff6060;
    }

    @include respond(tab-port) {
      width: 80%;
    }

    &__label {
      width: 100%;
    }
  }
}
