.courses {
  margin: 16rem 0 12rem 0;
  padding-bottom: 20rem;

  @include respond(tab-port) {
    margin-bottom: 40rem;
    padding-bottom: 32rem;
  }

  @include respond(tab-land) {
    margin-bottom: 55rem;
    padding-bottom: 45rem;
  }

  .btn {
    font-size: 1.3rem;
    border-radius: 8px;
    border: 1px solid transparent;
    font-weight: 900;
    padding: 1rem 3rem 1rem 3rem;
    outline: none;
    background-color: $color-primary;
    color: #fff;
    text-align: center;
    text-decoration: none;
  }

  h1 {
    text-align: center;
    font-size: 3rem;

    // @include respond(phone) {
    //   text-align: left;
    // }
  }

  &-content {
    margin: 3rem 10rem;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;

    @include respond(phone) {
      margin: 3rem 5rem;
    }

    .disable-card {
      color: grey;
      background-color: #d9d9d9;
    }
    .disable-card:hover {
      cursor: not-allowed;
      transform: scale(1);
    }

    &-card {
      box-shadow: 0rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      margin-right: 1rem;
      width: 30%;
      padding-bottom: 3rem;

      @include respond(phone) {
        width: 100%;
      }

      h2 {
        font-size: 2rem;
        text-align: center;
        padding: 2rem 2rem 2rem 2rem;
        padding-top: 0;
      }

      p {
        font-size: 1.5rem;
        padding: 0 2rem 2rem 2rem;
      }

      .detail {
        color: rgb(24, 153, 214);
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }

      .checked-icon {
        position: relative;
        color: green;
        font-size: 3rem;
        margin: 1rem;
        z-index: 2;
      }

      .not-checked {
        visibility: hidden;
      }
    }
  }
}
