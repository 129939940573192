.hear-about-us {
  margin: 18rem 0 0 0;
  padding-bottom: 14rem;

  @include respond(tab-land) {
    margin-bottom: 35rem;
  }

  h1 {
    font-size: 3rem;
    text-align: center;

    @include respond(phone) {
      text-align: left;
      margin-left: 3rem;
    }
  }

  &-content {
    margin: 1rem 5rem 5rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    button {
      margin-top: 3rem;
      width: 50%;
      border-radius: 16px;
      background-color: #58a700;
      color: #fff;
      border: 1px solid transparent;
      font-weight: 900;
      padding: 2rem 3rem 2rem 3rem;
      outline: none;
      font-size: 2rem;
      text-transform: uppercase;

      @include respond(phone) {
        width: 100%;
      }

      &:hover {
        background-color: #448000;
      }
    }

    &-card {
      border: 2px solid rgba(0, 0, 0, 0.125);
      background-color: #fff;
      border-radius: 0.8rem;
      margin-top: 5rem;
      width: 50%;

      @include respond(phone) {
        width: 100%;
      }

      hr {
        border: 0.01px solid #d9d9d9;
      }

      .active-source {
        background-color: rgba(24, 153, 214, 0.2);
        color: rgb(24, 153, 214);
        display: flex;
        padding: 12px 16px;
      }

      &-content {
        display: flex;
        padding: 12px 16px;

        h5 {
          font-weight: 900;
          font-size: 19px;
          flex-grow: 1;
        }
      }
    }
  }
}
