.learning-language {
  margin: 12rem 0 42rem 0;
  font-size: 1.4rem;

  .learning-language-settings {
    margin: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
  }

  .language-learning-cards-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    min-height: 90%;

    .language-learning-card {
      box-shadow: 0rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      margin-right: 1rem;
      width: 50%;
      height: auto;
      padding: 15px;

      h2 {
        font-size: 2rem;
        text-align: center;
        padding: 0 2rem 2rem 2rem;
      }

      p {
        font-size: 1.5rem;
        padding: 0 2rem 2rem 2rem;
      }
    }
  }
  .learning-language-btn {
    border-radius: 8px;
    border: 1px solid transparent;
    font-weight: 900;
    padding: 1rem 3rem 1rem 3rem;
    outline: none;
    background-color: $color-primary;
    color: #fff;
    text-align: center;
    text-decoration: none;
  }

  h1 {
    font-weight: 900;
    font-size: 27px;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .learning-language-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .language-learning-cards-row {
    flex-direction: column !important;
  }

  .language-learning-card {
    margin-top: 2rem !important;
  }

  .language-learning-card {
    width: 100% !important;
    height: 100% !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .learning-language-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .language-learning-cards-row {
    flex-direction: column !important;
  }

  .language-learning-card {
    margin-top: 2rem !important;
  }

  .language-learning-card {
    width: 100% !important;
    height: 100% !important;
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .learning-language-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }

  .language-learning-cards-row {
    flex-direction: column !important;
  }

  .language-learning-card {
    margin-top: 2rem !important;
  }

  .language-learning-card {
    width: 100% !important;
    height: 100% !important;
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .learning-language-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .language-learning-card {
    width: 100% !important;
    height: 100% !important;
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .learning-language {
    margin-bottom: 95rem !important;
  }
  .learning-language-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .learning-language {
    margin-bottom: 95rem !important;
  }
  .learning-language-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
