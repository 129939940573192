.result {
  width: 60vw;
  margin: 0 auto;
  margin-top: 25vh;
  background-color: #ffffff;
  padding: 0 25px 50px 25px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .result-heading {
    margin: 0 auto;
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    padding: 20px;
    margin-top: -8rem;
  }
  h1 {
    margin: 25px 0 10px 0;
  }

  .result-questions {
    margin: 0 auto;
    width: 100%;
    background-color: white;
    font-size: 1.5rem;
    border-radius: 15px;
    border: black 2px solid;

    .question {
      padding: 20px 10px;
      border-bottom: black dotted 2px;
      .correct {
        color: rgb(39, 189, 39);
      }

      .wrong {
        color: red;
      }
    }
    .answers {
      margin-top: 0.75rem;
      margin-left: 1.5rem;
    }
  }
}

.summary {
  display: block;
  cursor: pointer;
  outline: 0;
}

.summary::-webkit-details-marker {
  display: none;
}

.resultsummary_div {
  // background-color: white;
  color: black;
  font-size: 1.6rem;
  font-family: arial;
  margin: 0;
  padding: 3rem;
}

.tree-nav__item {
  display: block;
  white-space: nowrap;
  color: black;
  position: relative;
}

.tree-nav__item-title {
  cursor: pointer;
  display: block;
  outline: 0;
  color: darkslateblue;
  font-size: 1.5rem;
  line-height: 3.2rem;
}

.result-bars {
  font-size: 1.5rem;
  .bar-heading {
    padding: 15px 0 5px 0;
  }
}

.next-steps {
  h1 {
    text-align: center;
  }
  button {
    padding: 1.5rem 3rem;
    margin: 0 auto;
    display: block;
    font-size: 1.2rem;
    background-color: #fb9652;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
}
@media (max-width: 768px) {
  .result {
    width: 100%;
    margin-top: 20vh;
    border-radius: 0;
    box-shadow: none;
  }
  .result-bars {
    .bar-heading {
      padding: 15px 0 0 0;
    }
  }
}
