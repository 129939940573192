// COLORS
$color-primary: #f99529;
$color-secondary: #88bda0;
$color-background: #fcfcfc;

$color-white: #fcfcfc;
$color-black: #000;
$color-grey: #d8d8d8;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
