.nav {
  width: 100%;
  top: 0;
  // height: 20vh;
  padding: 1rem 0;
  background-color: $color-primary;
  position: fixed;
  display: flex;
  z-index: 999;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  transition: top 0.3s;
  box-shadow: 0rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
  @include respond(phone) {
    padding: 2rem 0;
  }

  .nav__quiz {
    position: absolute;
    left: 20rem;
    color: gray;

    &:hover {
      color: #000;
    }
  }

  .nav__learn {
    position: absolute;
    left: 6rem;
    color: gray;

    @include respond(phone) {
      left: 2rem;
    }

    &:hover {
      color: #000;
    }
  }

  &__logo {
    width: 10%;
    height: auto;
    // color: $color-white;
    color: $color-black;
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: 600;
    position: absolute;
    left: 5rem;
    // margin-right: 35rem;

    @include respond(tab-land) {
      // margin: 0 0 0 5rem;
      width: 15%;
      left: 3rem;
    }

    @include respond(tab-port) {
      width: 20%;
      left: 6rem;
    }

    @include respond(phone) {
      width: 30%;
      left: 2rem;
    }

    @include respond(small-phone) {
      width: 40%;
      margin: 0;
      left: 2rem;
    }

    &__image {
      width: 100%;
      height: auto;
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  margin-right: 5.5rem;
  &__nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: contents;

    &__select {
      text-align: center;
      padding: 1rem 3rem 1rem 1rem;
      margin: 2rem 0;
      background-color: $color-white;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: 600;
      border: none;
      border: 2px solid $color-primary;
      &__label {
        margin: 0 2rem 0 0;
        color: $color-white;
      }
      &__option {
        font-size: 2rem;
        border: none;
      }
    }
  }

  &__link {
    margin-right: 2rem;
    padding: 1rem 2rem;
    font-size: $default-font-size;
    color: $color-black;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;

    &__profile {
      &__checkbox {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
      }

      &__dropdown {
        overflow: hidden;
        cursor: pointer;
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        // position: relative;
        border: none;
        outline: none;
        box-shadow: 0rem 0.5rem 1rem rgba($color: $color-black, $alpha: 0.2);

        &:focus,
        &:active {
          .dropdown {
            transform: translate(0, 20px);
            opacity: 1;
            visibility: visible;
          }
        }

        &__image {
          // position: absolute;
          top: 0;
          left: 0;
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
        }

        .material-icons {
          border-radius: 100%;
          animation: ripple 0.6s linear infinite;
        }

        .dropdown {
          position: absolute;
          top: 80%;
          right: 6%;
          background: $color-white;
          width: 15rem;
          border-radius: 4px;
          box-shadow: 0 4px 12px rgba($color-black, 0.1);
          text-align: left;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s ease;
          list-style-type: none;

          &:before {
            content: '';
            position: absolute;
            top: -6px;
            right: 20px;
            width: 0;
            height: 0;
            box-shadow: 2px -2px 6px rgba($color-black, 0.05);
            border-top: 6px solid $color-white;
            border-right: 6px solid $color-white;
            border-bottom: 6px solid transparent;
            border-left: 6px solid transparent;
            transform: rotate(-45deg);
            mix-blend-mode: multiple;
          }

          li {
            z-index: 1;
            position: relative;
            background: $color-white;
            padding: 0 20px;
            color: #666;

            &.active {
              color: $color-primary;
            }

            &:first-child {
              border-radius: 4px 4px 0 0;
            }

            &:last-child {
              border-radius: 0 0 4px 4px;

              a {
                border-bottom: 0;
              }
            }
          }

          a {
            display: block;
            border-bottom: 1px solid rgba($color-black, 0.05);
            padding: 16px 0;
            color: inherit;
            font-size: 1.5rem;
            font-weight: 600;
            text-decoration: none;
            transition: 0.3s all;

            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }

    &__image {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    }
  }

  &__auth {
    display: flex;
    justify-items: center;
    align-items: center;

    &__icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 3rem 1rem 0 0.5rem;
    }

    &__icon {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $color-white;

      &--1 {
        margin-top: -3rem;
      }

      &--3 {
        margin-top: 3rem;
      }
    }
  }

  &__item {
    display: inline;
  }

  &__open {
    display: none;
  }

  &__link {
    margin-right: 2rem;
    padding: 0.5rem;
    font-size: $default-font-size;
    color: $color-white;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }

  &__link:hover {
    color: $color-black;
    transition: all 0.5s;
    text-decoration: none;
  }

  &__close {
    visibility: hidden;
    position: absolute;
    cursor: pointer;
    color: $color-primary;
    text-decoration: none;
    top: 0rem;
    right: 1.5rem;
    font-size: 12rem;
  }

  &__dropdown {
    text-align: center;
    padding: 1rem 2rem;
    background-color: $color-black;
    color: $color-white;
    font-size: 1.6rem;
    font-weight: 600;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-y: 6px;
    background-position-x: 80px;

    &:focus {
      outline: none;
    }

    &__items {
      font-size: 2rem;
      border: none;
    }
  }
}

// PROJECT STYLING

$border-radius: 3px;
// $primary: #f2f2f2;
$active: orange;
$animation: pulse;

.profile-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #f0f0f0;
  margin: auto;
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: 50%;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
    &:checked {
      transition: 0.5s all;
      & ~ ul {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-evenly;
        animation: $animation 0.5s;
        box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
      }

      & ~ img {
        background: $color-primary;
        border: 3px solid $color-white;
        box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
      }

      & ~ label {
        background: $color-primary;
        border: 5px solid black;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          right: calc(50% - 10px);
          display: block;
          border-style: solid;
          border-width: 7px 10px 0 10px;
          border-color: $color-primary transparent transparent transparent;
          width: 0;
          height: 0;
        }
      }
    }
  }

  img {
    display: inline-block;
    background: #d8d8d8;
    height: 4.5rem;
    width: 4.5rem;
    vertical-align: middle;
    margin-right: 1rem;
    margin: auto;
    border-radius: 50%;
    border: 2px solid $color-white;

    @include respond(phone) {
      width: 7rem;
      height: 7rem;
    }
  }

  span {
    display: inline-block;
    vertical-align: sub;
    width: 125px;
    margin-right: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ul {
    display: none;
    list-style: none;
    padding: 0;
    background: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    width: 15rem;
    border: 1px solid #e1e4e8;
    border-radius: 5px;
    box-shadow: 0 8px 24px #959da533;

    @include respond(tab-land) {
      width: 20rem;
      padding: 1rem 0;
    }

    li {
      width: 100%;

      a {
        display: block;
        padding: 0.75rem 2rem;
        text-decoration: none;
        color: $color-black;
        font-size: 1.5rem;
        font-weight: 400;

        @include respond(tab-land) {
          font-size: 2rem;
        }

        &:hover {
          color: $color-white;
          background: $color-primary;
        }
      }

      &:first-child a:hover {
        border-radius: $border-radius $border-radius 0 0;
      }

      &:last-child a:hover {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }

  & > label {
    position: relative;
    height: 3.5rem;
    display: block;
    text-decoration: none;
    background: transparent;
    color: #333;
    box-sizing: border-box;
    padding: 0.9rem;
    float: right;
    border-radius: 0 $border-radius $border-radius 0;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.container {
  .half {
    width: 50%;
    float: left;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.profile-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notification-bell:hover .notification-dropdown {
  display: block;
}
.notification-bell {
  position: relative;
  top: 4px;
  display: block;
  align-items: center;
  margin: 0 24px;
  font-size: 24px;
  cursor: pointer;
  color: #5b5b5b;
  min-height: 40px;
  .notification-bell-icon {
    padding-top: 4px;
  }
  .notification-count {
    position: absolute;
    width: 12px;
    min-height: 12px;
    left: 14px;
    top: 2px;
    font-size: 4px;
    background: #f9f9fa;
    border-radius: 60px;
    padding: 3px 6px;
    color: #f99529;
    font-weight: 700;
    text-align: center;
  }
  .notification-dropdown {
    display: none;
    flex-direction: column;
    position: absolute;
    right: -50%;
    top: 84%;
    background: white;
    min-width: 250px;
    font-size: 16px;
    box-shadow: 2px 2px 24px -14px orange;
    border-radius: 8px;

    .notification-title {
      padding: 12px 16px;
      border-bottom: 1px solid #cfcfcf;
    }
    .notification-item {
      display: flex;
      padding: 12px 16px;
      border-bottom: 1px solid #cfcfcf;
      div:nth-child(1) {
        padding: 4px 8px;
        background: #1cb0f6;
        height: 32px;
        width: 32px;
        margin-right: 12px;
        border-radius: 12px;
      }
      div:nth-child(2) {
        display: flex;
        align-items: center;
        line-height: 1.4;
        font-size: 2rem;
        font-weight: 900;
        color: #1cb0f6;
      }
    }
    // .notification-item:hover {
    //   border-left: 4px solid #f99529;
    // }
    .notification-all {
      padding: 12px 16px;
      text-align: center;
      text-decoration: underline;
      color: #f99529;
    }
  }
}

.profile-name {
  display: inline;
  margin-right: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
}

.navigation_profile {
  border-style: dashed;
  // margin-left: 3rem;
  border-radius: 50%;
  height: 4.5rem;
  width: 4.5rem;
  vertical-align: middle;
  border-width: 2px;
  font-size: 2rem;
  align-items: center;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  vertical-align: middle;
  // margin-left: 130rem;
  color: #333;
  // color: grey;

  &:hover {
    color: #000;
  }
}

.navigation_icon {
  // margin-left: 3rem;
  font-size: 3rem;
  position: relative;
  top: 0.6rem;
  right: 0.5rem;
  // vertical-align: middle;
}

.right-nav-icons {
  position: absolute;
  right: 15rem;
  color: grey;
  margin-right: 3rem;

  li {
    display: inline;
    font-size: 3rem;
    margin: 0 1rem 0rem 1rem;

    span {
      font-size: 1.5rem;
      position: relative;
      bottom: 0.5rem;
    }

    &:hover {
      color: #000;
    }
  }
}

.active_nav {
  color: #298df9;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 7.2rem;
  top: 5.65rem;
  background-color: #fff;
  width: 30rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;

  .small-device-dropdown-content {
    display: none;
  }

  a {
    color: #1cb0f6;
    padding: 12px 16px;
    font-size: 2rem;
    text-decoration: none;
    display: block;
    font-weight: 900;
    letter-spacing: 0.8px;
  }

  hr {
    border: 1px solid #d9d9d9;
  }

  a:hover {
    background-color: #f1f1f1;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 37.5em) {
  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
    margin-right: 1rem;
    margin-left: 0;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 7rem;
  }

  .dropdown-content {
    top: 7.1rem;
    .small-device-dropdown-content {
      display: inherit;
      visibility: visible;
    }

    .large-device-dropdown-content {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  .profile-detail {
    margin-left: 5rem;
  }

  .profile-name {
    right: 4rem;
    visibility: hidden;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
  }

  .dropdown-content {
    top: 7.1rem;
    right: 2.1rem;

    .small-device-dropdown-content {
      display: inherit;
      visibility: visible;
    }

    .large-device-dropdown-content {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  .profile-detail {
    margin-left: 8rem;
  }

  .profile-name {
    right: 8rem;
    visibility: hidden;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
  }

  .dropdown-content {
    top: 7.1rem;
    right: 5.1rem;

    .small-device-dropdown-content {
      display: inherit;
      visibility: visible;
    }

    .large-device-dropdown-content {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  .profile-detail {
    margin-left: 25rem;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
  }

  .dropdown-content {
    top: 7.1rem;
    right: 3.9rem;

    .small-device-dropdown-content {
      display: inherit;
      visibility: visible;
    }

    .large-device-dropdown-content {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .nav__learn {
    left: 4rem !important;
  }

  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  .profile-detail {
    margin-left: 45rem;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
  }

  .dropdown-content {
    top: 7.1rem;
    right: 4.9rem;

    .small-device-dropdown-content {
      display: inherit;
      visibility: visible;
    }

    .large-device-dropdown-content {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  .profile-detail {
    margin-left: 72rem;
  }

  .profile-name {
    right: 0.5rem;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
  }

  .dropdown-content {
    top: 6.1rem;
    right: 3.9rem;
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .navigation_profile {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  .profile-detail {
    margin-left: 85rem;
  }

  .profile-name {
    right: 0.5rem;
  }

  .navigation__auth {
    margin-left: 0;
  }

  .nav__logo {
    margin-right: 0;
  }

  .navigation_icon {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
  }

  .dropdown-content {
    top: 6.1rem;
    right: 5.1rem;
  }
}

@media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  // .dropdown-content {
  //   top: 5.7rem;
  //   right: 2.1rem;
  // }
  // .profile-detail {
  //   margin-right: 8rem;
  // }
}

@media only screen and (min-device-width: 1366px) and (max-width: 2560px) {
  .notification-bell {
    font-size: 32px;
  }
}
