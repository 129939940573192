.profile {
  margin: 12rem 0 0 0;
  font-size: 1.4rem;

  .profile-settings {
    margin: 10rem;
    padding-left: 12rem;
    padding-right: 12rem;
    /*display: grid;*/
    gap: 2rem;
    /*grid-template-columns: repeat(2, minmax(375px, 1fr));*/
  }

  .statistics {
    margin-top: 3rem;
    h1 {
      font-size: 3rem;
    }
    .statistics-show {
      display: flex;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .statistics-card {
      width: 100%;
      padding: 2rem;
      border: 2px solid rgb(224, 224, 222);
    }
  }

  .profile-form {
    margin-right: 2rem;

    a {
      text-decoration: none;
    }

    .profile-form-heading {
      border-bottom: 2px solid black;
      display: flex;
      align-items: flex-start;
      padding-bottom: 1rem;
      .profile-icon {
        font-size: 15rem;
        margin: 5% auto;
      }
      .profile-heading-content {
        .profile-form-icon {
          font-size: 1.8rem;
          width: 10em;
          height: 10em;
          border: 1px solid black;
          img {
            height: 100%;
            width: auto;
          }
        }
      }
    }

    .profile-btn {
      border-radius: 8px;
      background-color: rgb(28, 176, 246);
      color: #fff;
      border: 1px solid transparent;
      font-weight: 900;
      padding: 1rem 3rem 1rem 3rem;
      outline: none;
    }

    .profile-form-content {
      padding: 0 2rem;

      .verified-icon {
        font-size: 2rem;
        color: green;
        padding: 1rem;
      }
      h1 {
        font-weight: 900;
        font-size: 3rem;
      }
      h2 {
        font-size: 1.5rem;
      }
      .edit-icon {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .profile-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
  .profile {
    .statistics-show {
      flex-direction: column;
    }
    .profile-settings {
      margin: 2rem;
    }
  }

  .profile-form {
    margin-right: 0 !important;
    .profile-form-heading {
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .profile-btn {
      margin-top: 2rem;
    }

    .profile-form-content {
      .non-input-content {
        flex-direction: column !important;
        p {
          font-size: 1.5rem !important;
        }

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .profile-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
  .profile {
    .statistics-show {
      flex-direction: column;
    }
    .profile-settings {
      margin: 2rem;
    }
  }

  .profile-form {
    margin-right: 0 !important;
    .profile-form-heading {
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .profile-btn {
      margin-top: 2rem;
    }

    .profile-form-content {
      .non-input-content {
        flex-direction: column !important;
        p {
          font-size: 1.5rem;
        }

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .profile-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
  .profile {
    .statistics-show {
      flex-direction: column;
    }
    .profile-settings {
      margin: 2rem;
    }
  }
  .profile-form {
    margin-right: 0 !important;
    .profile-form-heading {
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .profile-btn {
      margin-top: 2rem;
    }

    .profile-form-content {
      .non-input-content {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .profile-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
  .profile {
    .statistics-show {
      flex-direction: column;
    }
    .profile-settings {
      margin: 2rem;
    }
  }
  .profile-form {
    margin-right: 0 !important;
    .profile-form-heading {
      margin-bottom: 0rem !important;
      margin-top: 3rem !important;
    }
    .profile-btn {
      margin-top: 2rem;
    }

    .profile-form-content {
      .non-input-content {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .profile-settings {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .profile {
    .statistics-show {
      flex-direction: column;
    }
    .profile-settings {
      margin: 2rem;
    }
  }
  .profile-form {
    margin-right: 0 !important;
    .profile-form-content {
      .non-input-content {
        flex-direction: column !important;

        label {
          font-size: 2rem !important;
          margin-bottom: 1rem !important;
        }

        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .profile-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.graph-progress-bar div div {
  background: linear-gradient(
    181deg,
    #f99529,
    #f99529,
    #ed8615,
    #c26603
  ) !important;
}
.achievement-progress-bar div div {
  background: linear-gradient(
    181deg,
    #ffc107,
    #ffc107,
    #f4b801,
    #daa402
  ) !important;
}
