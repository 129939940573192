.vocabcard__processbar__progress {
  margin: 1rem auto;
  padding: 0;
  width: 100%;
  height: 4rem;
  overflow: hidden;
  background: $color-white;
  border: 1px solid $color-black;

  border-radius: 6px;

  &__bar {
    // height: 100%;
    // width: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all;

    &__percent {
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
    }
  }
}

#Learning {
  background: #c41b16;
}

#Reviewing {
  background: #fb9652;
}

#Mastered {
  background: #15a56a;
}
