.meet-trilingo {
  margin: 17rem 0 0 0;

  &-content {
    margin: 5rem 15rem 5rem 8rem;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 2rem;

    &-img {
      h1 {
        font-size: 4rem;
      }

      p {
        font-size: 1.7rem;
      }

      img {
        width: 50%;
        height: auto;
      }
    }

    &-features {
      h1 {
        font-weight: 400;
        color: #696969;
        font-size: 3rem;
      }
      h2 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1.7rem;
        text-align: left;
      }

      &-list {
        margin-top: 5rem;

        &-item {
          display: flex;
          flex-direction: row;
          margin: 2rem 0 2rem 0;
          gap: 2rem;

          .features-icon {
            border-radius: 50%;
            border: 1px solid #ff5700;
            color: #ff5700;
            padding: 2.5rem;
            width: 8rem;
            height: 8rem;
            font-size: 2.5rem;
            align-items: center;
            display: inline-flex;
            font-weight: 700;
            justify-content: center;
            vertical-align: middle;
          }

          .features-text {
            h5 {
              font-size: 2.5rem;
            }

            h6 {
              font-weight: normal;
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    @include respond(phone) {
      margin: 8rem;
      grid-template-columns: 100%;
    }
  }
}
