// required. do not change.
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  // required. do not change.
  .backdrop {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // Your modal s child components body
    // create a similar below structure for adding customizing your own child component inside the modal
    .modal-body-report {
      position: relative;
      width: 400px;
      min-height: 200px;
      font-size: 53px;
      color: black;
      background: white;
      border-radius: 16px;
      padding: 24px 16px;
      justify-content: center;
      @include respond(phone) {
        width: 90%;
      }
      @include respond(small-phone) {
        max-height: 100%;
        width: 90%;
      }

      .close {
        color: #999;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 8px;
        right: 8px;
        font-weight: bold;
        font-size: 2rem;
        cursor: pointer;
        font-family: cursive;
      }
      .report-container {
        width: 100%;
        padding: 8px 0;
        .report-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          margin: 6px 0;
        }
        .report-column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          margin-top: 24px;
        }
        // textarea field css
        .report-input {
          border: 2px solid #999;
          border-radius: 4px;
          padding: 8px 16px;
          width: 90%;
          font-family: sans-serif;
          font-size: 1.6rem;
          font-weight: 400;
          color: #888;
          outline: none;
          max-width: 100%;
        }
        // radio button css
        // checkbox button tick icon
        input.report-radio:checked:after {
          content: '\2714';
          font-size: 14px;
          position: absolute;
          top: 0px;
          left: 5px;
          color: #ff9800;
          font-weight: 700;
        }
        .report-radio {
          box-sizing: border-box;
          margin: 0 16px;
          align-self: flex-start;
          -webkit-appearance: none;
          background: #ffffff;
          color: azure;
          background-color: #fafafa;
          border: 3px solid #ccc;
          box-shadow: 0 1px 1px #ccc,
            inset 0px -5px 6px -26px rgba(255, 255, 255, 0.6);
          padding: 10px;
          border-radius: 8px;
          display: inline-block;
          position: relative;
        }
        .report-radio {
          flex: 1 1;
          max-width: 24px;
        }
        .report-radio:checked {
          background-color: #ffffff;
          border: 3px solid #ff9800;
          color: #ff9800;
          box-shadow: 0 1px 2px #ff9800,
            inset 0px -15px 10px -12px rgba(255, 255, 255, 0.678);
        }
        .report-radio-label {
          flex: 1;
          font-size: 1.6rem;
        }
        .report-button {
          padding: 8px 24px;
          background: #ff9800;
          color: white;
          border: 0;
          margin: 4px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1.6rem;
          transition: 0.4s ease-in-out;
          box-shadow: none;

          @include respond(small-phone) {
            width: 90%;
          }
        }
        .report-button:hover {
          box-shadow: 3px 3px 6px -3px #ff9800;
          transition: 0.4s ease-in-out;
        }
      }
    }
  }
}

.modal-header {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 16px;
}
