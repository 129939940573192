.goal {
  margin: 12rem 0 0 0;
  font-size: 1.4rem;

  .save-btn {
    border-radius: 8px;
    background-color: #d9d9d9;
    color: #666666;
    border: 1px solid transparent;
    font-weight: 900;
    padding: 1rem 3rem 1rem 3rem;
    outline: none;
  }

  .save-btn-changed {
    background-color: #58a700;
    color: #fff;
  }

  .goal-settings {
    margin: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
  }

  .daily-goals {
    margin-right: 2rem;

    .daily-goals-heading {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3.5rem;

      h1 {
        font-weight: 900;
        font-size: 27px;
      }
    }

    .daily-goals-card {
      border: 2px solid rgba(0, 0, 0, 0.125);
      background-color: #fff;
      border-radius: 0.8rem;
      margin-top: 5rem;
      width: 100%;

      hr {
        border: 0.01px solid #d9d9d9;
      }

      .active-goal {
        background-color: rgba(24, 153, 214, 0.2);
        color: rgb(24, 153, 214);
        display: flex;
        padding: 12px 16px;
      }

      .daily-goals-card-content {
        display: flex;
        padding: 12px 16px;

        h5 {
          font-weight: 900;
          font-size: 19px;
          flex-grow: 1;
        }

        p {
          font-size: 1.7rem;
          padding-left: 6rem;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
  .goal-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .goal-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) {
  .goal-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
}

@media only screen and (min-device-width: 480px) and (max-width: 540px) {
  .goal-settings {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 3rem !important;
  }
}

@media only screen and (min-device-width: 541px) and (max-width: 768px) {
  .goal {
    margin-bottom: 40rem !important;
  }

  .goal-settings {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media only screen and (min-device-width: 769px) and (max-width: 1279px) {
  .goal {
    margin-bottom: 80rem !important;
  }

  .goal-settings {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-bottom: 15rem !important;
  }
}

@media only screen and (min-device-width: 1280px) and (max-width: 1366px) {
  .goal {
    margin-bottom: 100rem !important;
  }
}

//choose-goals
.daily-goals-card {
  border: 2px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  border-radius: 0.8rem;
  margin-top: 5rem;
  width: 100%;

  hr {
    border: 0.01px solid #d9d9d9;
  }

  .active-goal {
    background-color: rgba(24, 153, 214, 0.2);
    color: rgb(24, 153, 214);
    display: flex;
    padding: 12px 16px;
  }

  .daily-goals-card-content {
    cursor: pointer;
    display: flex;
    padding: 12px 16px;

    h5 {
      font-weight: 900;
      font-size: 19px;
      flex-grow: 1;
    }

    p {
      font-size: 1.7rem;
      padding-left: 6rem;
    }
  }
}
.daily-goals-heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.5rem;

  h1 {
    font-weight: 900;
    font-size: 27px;
  }
}
.save-btn {
  border-radius: 8px;
  cursor: pointer;
  background-color: #d9d9d9;
  color: #666666;
  border: 1px solid transparent;
  font-weight: 900;
  padding: 1rem 3rem 1rem 3rem;
  outline: none;
}

.save-btn-changed {
  background-color: #58a700;
  color: #fff;
}

.choose_goals {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.choose_goals_container {
  width: 640px;
}
